import { watch } from "@vue/composition-api";

function mapInvalidTimes (events) {
	return events.reduce( (map, time) => {
		const [h, m] = time.split(":");
		const [hours, minutes] = [+h, +m];

		if(!map[hours]) map[hours] = [minutes];
		else map[hours].push(minutes);

		return map;
	}, {});
}

export default function(eventFactory) {
	let hour = -1;

	let invalidTimes = [];
	watch(eventFactory, events => {
		invalidTimes = mapInvalidTimes(events);
	}, {immediate:true});


	const isTimeAvailable = (hour, minutes) => {
		const invalidTimesList = invalidTimes[hour] || [];
		if(!invalidTimesList.length) return true;

		return !invalidTimesList.some( entry => entry === minutes );
	};

	const allowedStep = m => m % 5 === 0;
	const allowedMinutes = (minutes) => {
		return allowedStep(minutes) && isTimeAvailable(hour, minutes);
	}

	const onPeriodUpdated = (value) => {
		if(value === "am" && hour >= 12) hour -= 12;
		else if(value === "pm" && hour < 12) hour += 12;
	};

	const onHourClicked = (h) => hour = h;

	return { allowedStep, allowedMinutes, onPeriodUpdated, onHourClicked };
}
