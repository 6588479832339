<template>
	<div class="time-event-dialog">
		<v-dialog offset-x persistent
			:fullscreen="$vuetify.breakpoint.smAndDown"
			:hide-overlay="$vuetify.breakpoint.smAndDown"
			:max-width="maxWidth"
			:value="open"
			:close-on-content-click="false"
			transition="dialog-bottom-transition"
			overlay-color="grey darken-3"
		>
			<v-card flat color="lighten-4">
				<v-toolbar dark :color="event.color">
					<v-btn icon @click="$router.back(); form.cancel();">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>{{title}}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn dark text
							color="primary"
							:disabled="form && (form.invalid || !dataChanged)" @click="form.submit()"
						> Save </v-btn>
					</v-toolbar-items>
				</v-toolbar>

				<v-card-text>
					<ScheduleEventForm
						ref="form"
						:schedule="schedule"
						:event="event"
						class="pt-8"
						@changed="onDataChanged"
						@save="$emit('save', $event); $router.back()"
						@error="snackbar.showWithoutAction($event)"
					/>
				</v-card-text>

			</v-card>
		</v-dialog>

	</div>
</template>

<script>
import { ref, computed } from "@vue/composition-api";

import settings from "@/config/settings";
import useSnackbar from "@/composables/useSnackbar";
import ScheduleEventForm from "./ScheduleEventForm.vue";

const props = {
	schedule: { required:true, type:Array },
	title: { required:true, type:String },
	event: { type:Object, default: () => ({start:null, setpoint:72}) },
};

export default {
	name: "TimeEventDialog",
	components: { ScheduleEventForm },
	props,

	beforeRouteLeave(to, from, next) {
		this.open = false;
		setTimeout(() => {
			this.$emit("close");
			next();
		}, 250);
	},

	setup(props, {root}) {
		const snackbar = useSnackbar();
		const form = ref(null);
		const open = ref(true);
		const maxWidth = computed(() => {
			return settings.dialog.widths[root.$vuetify.breakpoint.name];
		});

		const dataChanged = ref(false);
		const onDataChanged = (event) => {
			dataChanged.value = props.event.start !== event.start || props.event.setpoint !== event.setpoint;
		};

		return { snackbar, open, form, maxWidth, dataChanged, onDataChanged };
	}
}
</script>
