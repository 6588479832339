<template>
	<v-slider
		:min="min"
		:max="max"
		:value="value"
		@input="update"
		color="success"
		always-dirty
	>
		<template v-slot:prepend>
			<v-icon color="success" @click="decrement">mdi-minus</v-icon>
		</template>

		<template v-slot:append>
			<v-icon color="success" @click="increment">mdi-plus</v-icon>
		</template>

	</v-slider>
</template>

<script>
import settings from "@/config/settings.json";
const { min, max } = settings.temperature;

const props = {
	value: { type:Number, required:true },
	min: { type:Number, validator: value => value >= min },
	max: { type:Number, validator: value => value <= max },
};

export default {
	name: "TemperatureSlider",
	props,

	setup(props, {emit}) {
		const update = emit.bind(null, "input");
		const decrement = () => update(props.value - 1);
		const increment = () => update(props.value + 1);

		return {decrement, increment, update};
	},
}
</script>
