<template>

	<div v-if="schedule" class="schedule-overview fill-height">

		<DaySlider v-model="day"></DaySlider>

		<!-- Mobile View -->
		<v-window
			v-if="$vuetify.breakpoint.smAndDown && !isScheduleEmpty"
			class="event-list-window mt-0 pb-6 fill-height full-width"
			:value="day"
			:touch="{ left: gotoNextDay, right: gotoPreviousDay, }"
		>
			<template>
				<v-window-item v-for="(day, i) in days" :key="i" :value="day.value" class="py-1">
					<v-subheader>{{day.value | capitalize}}</v-subheader>
					<DayEventList
						:day="schedule.getDay(day.value)"
						@event:clicked="openEditEventDialog($event, day.value)"
					></DayEventList>

				</v-window-item>
			</template>
		</v-window>

		<!-- Mobile View (No Events) -->
		<div v-else-if="$vuetify.breakpoint.smAndDown && isScheduleEmpty">
			<div class="d-flex flex-column flex-grow-1 align-center mt-16" :style="{'padding-top':'10vh'}">
				<div class="d-flex no-results-image-wrapper grey lighten-1">
					<v-icon size="26vh" role="img" color="grey darken-2">mdi-calendar-search</v-icon>
				</div>
				<h4 align="center" class="text-h4 text--secondary">Empty Schedule</h4>
			</div>
		</div>

		<!-- Desktop View -->
		<div v-else-if="!isScheduleEmpty" class="d-flex justify-space-around full-width">
			<div
				v-for="(day, i) in days"
				:key="i"
				class="day-column"
			>
				<v-slide-y-transition group>
					<v-card tile
						color="primary"
						v-for="(event, k) in schedule.getDay(day.value).events"
						:key="`${day.value}-${k}`"
						class="day-box mb-4"
						@click="openEditEventDialog(event, day.value)"
					>

						<div class="d-flex flex-column align-center justify-center fill-height">
							<div class="temperature text--primary">
								<span class="text-h4 mt-1">{{ event.setpoint }}</span>
								<v-icon size="20" class="mt-n8">mdi-temperature-fahrenheit</v-icon>
							</div>

							<div class="text--secondary text-body-2 font-weight-bold">{{ event.start | fromMilitaryTime }}</div>
						</div>

					</v-card>
				</v-slide-y-transition>
			</div>
		</div>

		<!-- Desktop View (No Events) -->
		<template v-else>
			<div class="d-flex flex-column flex-grow-1 align-center" :style="{'position':'relative', top:'15vh'}">
				<div class="d-flex no-results-image-wrapper grey lighten-1">
					<v-icon size="26vh" role="img" color="grey darken-2">mdi-calendar-search</v-icon>
				</div>
				<h4 align="center" class="text-h4 text--secondary">Empty Schedule</h4>
			</div>
		</template>

		<!-- Add Event TimeEventDialog -->
		<router-view name="create"
			title="Add Event"
			:schedule="selectedDayEvents"
			:event="{ start:null, setpoint:72 }"
			@save="addEvent"
		/>

		<!-- Edit Event TimeEventDialog -->
		<router-view name="edit"
			title="Edit Event"
			:schedule="selectedDayEvents"
			:event="selectedEvent.event"
			@close="selectedEvent.reset"
			@save="handleEventEdited"
		/>

		<div v-if="showMenu" class="backdrop" @click="showMenu=false"></div>
		<portal to="header-options">
			<v-menu offset-y :value="showMenu">
				<template v-slot:activator="{ on, attrs }">
					<v-btn dark icon v-bind="attrs" v-on="on" @click="showMenu=true">
						<v-icon>mdi-dots-vertical</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item>
						<v-list-item-title @click.prevent="dialog = true">Copy Days</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</portal>

		<DaySelectionDialog
			title="Copy Schedule to Day(s)"
			confirmText="Copy"
			v-model="copyToDays"
			:disabledDays="day!=null ? [day] : []"
			:open="dialog"
			@update:open="dialog = $event"
			@confirm="copySchedule()"
		/>

	</div>
</template>

<script>
import { ref, reactive, computed, watch } from "@vue/composition-api";

import { useSchedule } from "@/composables/schedule";
import useSnackbar, { TextAction } from "@/composables/useSnackbar";
import useFab from "@/composables/useFab";
import useApplicationTitle from "@/composables/useApplicationTitle";

import DaySelectionDialog from "@/components/schedule/DaySelectionDialog";
import DaySlider from "@/components/schedule/DaySlider";
import DayEventList from "@/components/schedule/DayEventList";

const days = [
	{ value:"sunday",    label:"S" },
	{ value:"monday",    label:"M" },
	{ value:"tuesday",   label:"T" },
	{ value:"wednesday", label:"W" },
	{ value:"thursday",  label:"T" },
	{ value:"friday",    label:"F" },
	{ value:"saturday",  label:"S" },
];

/*eslint no-mixed-spaces-and-tabs: "error"*/
function getToday() {
	const index = new Date().getDay();
	return days[index].value;
}

export default {
	name: "ScheduleOverview",
	components: { DaySlider, DayEventList, DaySelectionDialog },
	setup(props, {root}) {
		const { schedules, schedule, invoke, undo, setActiveScheduleId } = useSchedule();
		const { setTitle } = useApplicationTitle();

		const undoAction = TextAction("Undo", undo);

		const selectedEvent = reactive({
			day: "",
			event: { start:null, setpoint:72 },
			reset: () => {
				selectedEvent.day = "";
				selectedEvent.event = { start:null, setpoint:72 };
			}
		});

		const openEditEventDialog = (event, day) => {
			selectedEvent.event = event;
			selectedEvent.day = day;
			const index = selectedDayEvents.value.findIndex(el => el.start === event.start);
			root.$router.push({ name:"EditEvent", params: { index }, query: { day }  });
		};


		const day = ref(getToday());

		const copyToDays = ref([]);
		const dialog = ref(false);

		const copySchedule = () => {
			invoke({type:"copyDay", day:day.value, list:copyToDays.value});
			snackbar.show("Schedule copied", {action:undoAction})
		};

		const snackbar = useSnackbar();
		const fab = useFab();
		fab.setListener({
			click: () => {
				const editingEvent = root.$route.name === "EditEvent";
				const creatingEvent = !editingEvent && root.$route.name === "Schedules";
				if(editingEvent) {
					handleEventDeleted(selectedEvent.event);
					root.$router.back();
				}
				else if(creatingEvent) {
					root.$router.push({ name:"CreateEvent", query: { day:day.value } });
				}
			},
		});

		if(root.$route.name === "EditEvent") {
			fab.setAttrs({color:"red"});
			fab.setIcon("mdi-delete");
			fab.show();
		}

		watch(() => [root.$route.name, dialog.value], ([name, showingCopyDialog]) => {
			const addingEvent = name === "CreateEvent";
			if(addingEvent || showingCopyDialog) {
				fab.hide();
				return;
			}

			const editingEvent = name === "EditEvent";
			const color = editingEvent ? "red" : "green";
			const icon = editingEvent ? "mdi-delete" : "mdi-plus";

			fab.setAttrs({color});
			fab.animate(() => {
				fab.setIcon(icon);
			});
		});

		const gotoPreviousDay = () => {
			const index = days.findIndex(({value}) => value === day.value);
			if(index === 0) return;
			day.value = days[index-1].value;
		};
		const gotoNextDay = () => {
			const index = days.findIndex(({value}) => value === day.value);
			if(index === days.length-1) return;
			day.value = days[index+1].value;
		};

		const selectedDayEvents = computed(() => schedule.value.getDay(day.value).events);
		const addEvent = ({start, setpoint}) => {
			invoke({ type:"addEvent", day:day.value, event:{start, setpoint, mode:"off"} });
			snackbar.show("Event added", {action:undoAction});
		};

		const handleEventEdited = (data) => {
			invoke({type:"editEvent", day:selectedEvent.day, oldValue:selectedEvent.event, newValue:data});
			selectedEvent.event = data;
			snackbar.show("Edited event", {action:undoAction});
		};

		const handleEventDeleted = (event) => {
			invoke({type:"deleteEvent", day:selectedEvent.day, start:event.start});
			selectedEvent.event = event;
			snackbar.show("Removed event", {action:undoAction});
		};

		const isScheduleRouteName = routeName => ["Schedules", "CreateEvent", "EditEvent"].includes(routeName);
		const setSelectedEventFromRoute = (route)  => {
			const { params, query } = route;

			if("day" in query) {
				const activeDay = (query.day || "").toLowerCase();
				const isValidDay = days.some(d => d.value === activeDay);
				if(isValidDay) {
					day.value = activeDay;
					selectedEvent.day = activeDay;
				}
			}

			if("id" in params) setActiveScheduleId(params.id);

			if("id" in params && "index" in params) {
				const id = params.id;
				const sch = schedules.value[id];
				const events = sch.getDay(day.value).events;
				const index = params.index;
				if(index >= 0 && index < events.length) {
					selectedEvent.event = events[index];
				}
			}
		};

		if(isScheduleRouteName(root.$route.name)) setSelectedEventFromRoute(root.$route);

		watch(() => root.$route.params, () => {
			const route = root.$route;
			if(!isScheduleRouteName(route.name)) return;
			setSelectedEventFromRoute(route);
		}, {immediate:true});

		watch(schedule, (schedule) => {
			const title = (schedule && schedule.name) ? `${schedule.name} Schedule` : "Schedule";
			setTitle(title);
		}, {immediate:true});

		const showMenu = ref(false);
		const isScheduleEmpty = computed(() => schedule.value.isEmpty);

		return { isScheduleEmpty, handleEventEdited, handleEventDeleted, addEvent, selectedEvent, selectedDayEvents, showMenu, day, gotoPreviousDay, gotoNextDay, copyToDays, copySchedule, snackbar, dialog, undo, schedule, openEditEventDialog, days };
	},
}
</script>

<style lang="scss">
	@import "@/styles/local.scss";

	.schedule-overview {
		.backdrop {
			position: absolute;
			background-color: rgba(0, 0, 0, 0);
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}

		.event-list-window {
			margin-top: 64px;
			background: $app-background-color;
		}

		.day-column {
			width: #{map-get($desktop-schedule-view, 'width')};
			min-width: #{map-get($desktop-schedule-view, 'min-width')};
			max-width: #{map-get($desktop-schedule-view, 'max-width')};

			.day-box {
				height: #{map-get($desktop-schedule-view, 'min-width')};
			}
		}

		.no-results-image-wrapper {
			height: 38vh;
			width: 38vh;
			border-radius: 50%;
			justify-content: center;
		}

		.no-results-image-wrapper + h4 {
			margin-top: 8vh;
		}
	}
</style>
