<template>
	<v-alert tile
		v-ripple
		elevation="4"
		:color="color"
		:colored-border="!disabled"
		:border="border"
		class="day-event-card"
	>
		<div class="d-flex flex-row" :class="textColorClasses">
			<div>
				<v-icon v-if="icon" class="mr-3" :color="iconColorClasses">{{icon}}</v-icon>
				<span class="ml-1">
					<slot>{{description}}</slot>
				</span>
			</div>
			<v-spacer/>
			<div class="ml-4 d-flex align-center setpoint">
				<v-icon :color="iconColorClasses" class="setpoint-icon">mdi-thermometer</v-icon>
				<span class="text-body-1 font-weight-bold">{{event.setpoint}}</span>
				<v-icon :color="iconColorClasses" size="16" class="mt-n1">mdi-temperature-fahrenheit</v-icon>
			</div>
		</div>
	</v-alert>
</template>

<script>
import { computed } from "@vue/composition-api";

export default {
	name: "DayEventCard",
	props: {
		description: { type:String },
		event: { type:Object, required:true },
		color: { type:String, default:() => "secondary" },
		disabled: { type:Boolean, default:false },
		icon: { type:String, default:null },
	},
	setup(props) {
		const border = computed(() => !props.disabled ? "left" : undefined);
		const textColorClasses = computed(() => props.disabled ? "grey--text text--darken-1" : "text--primary");
		const iconColorClasses = computed(() => props.disabled ? "grey darken-1" : "");
		const alertColor = computed(() => props.disabled ? "grey" : props.color);
		return { border, textColorClasses, iconColorClasses, alertColor };
	}
}
</script>

<style lang="scss">
	.setpoint {
		> .setpoint-icon {
			margin-right: 3px;
			margin-top: -3px;
		}
	}

	.day-event-card {
		background-color: #424242 !important;
	}
</style>
