<template>
	<header
		class="day-picker"
		:class="{ 'sidenav-open': !$vuetify.breakpoint.mobile }"
	>
		<v-chip-group
			v-if="$vuetify.breakpoint.smAndDown"
			:value="value"
			class="ma-2"
			active-class="primary text--accent-4"
			mandatory
		>
			<span class="d-flex justify-space-around full-width">
				<v-chip
					color="transparent"
					v-for="(day, i) in days" :key="i"
					:class="{ 'text-decoration-underline': isToday(i) }"
					:value="day.value"
					@input="$event && update(day.value)"
				>{{day.label}}</v-chip>
			</span>
		</v-chip-group>

		<!-- Desktop View -->
		<div v-else class="d-flex justify-space-around align-center full-width fill-height">
			<div
				v-for="(day, i) in days"
				:key="i"
				class="day-column-header"
				:class="dayColumnHeaderClasses(day.value, i)"
				@click="update(day.value)"
				color="accent"
			>
				{{ day.value | capitalize }}
			</div>
		</div>

	</header>
</template>

<script>
const days = [
	{ value:"sunday",    label:"S" },
	{ value:"monday",    label:"M" },
	{ value:"tuesday",   label:"T" },
	{ value:"wednesday", label:"W" },
	{ value:"thursday",  label:"T" },
	{ value:"friday",    label:"F" },
	{ value:"saturday",  label:"S" },
];

export default {
	name: "DaySlider",
	props: {
		value:{ type:String, required:true },
	},
	setup(props, {emit}) {
		const update = emit.bind(null, "input");
		const isToday = (i) =>  new Date().getDay() === i;
		const dayColumnHeaderClasses = (day, i) => {
			const list = isToday(i) ? ["text-decoration-underline"] : [];
			if(day !== props.value) return list;
			return ['font-weight-bold', 'amber--text', 'text--darken-4'].concat(list);
		};
		return { update, days, isToday, dayColumnHeaderClasses };
	}
}

</script>

<style lang="scss">
	@import "~vuetify/src/styles/styles.sass";
	@import "@/styles/local.scss";

	$day-picker-height: 64px;
	$chip-font-family: "Roboto Condensed";

	.sidenav-open {
		padding-left: $app-sidenav-width;
	}

	.full-width {
		width: 100%;
	}

	.day-picker {
		position: fixed;
		z-index: 4;
		background-color: $app-background-color;
		left: 0;
		right: 0;
		height: $day-picker-height;

		+ * {
			padding-top: $day-picker-height;
		}

		// Force circular chips
		.v-chip {
			font-family: $chip-font-family;
			font-weight: bold;
			width: 32px;
			height: 32px;

			// Center M/W characters in chips
			&:nth-of-type(2),
			&:nth-of-type(4) {
				> * {
					position: relative;
					left: -2px;
				}
			}

			&:hover::before {
				opacity: 0 !important;
			}
		}

		.day-column-header {
			text-align: center;
			width: #{map-get($desktop-schedule-view, 'width')};
			min-width: #{map-get($desktop-schedule-view, 'min-width')};
			max-width: #{map-get($desktop-schedule-view, 'max-width')};
		}
	}

	.setpoint-icon {
		margin-right: 3px;
		margin-top: -3px;
	}

</style>
