<template>
	<v-dialog
		:hide-overlay="$vuetify.breakpoint.smAndDown"
		overlay-color="grey darken-3"
		v-model="dialog"
		max-width="290"
		persistent
	>
		<v-card class="copy-dialog">
			<v-card-title> {{title}} </v-card-title>

			<v-card-text>
				<v-checkbox
					label="Select All"
					:indeterminate="value.length > 0 && value.length < 6"
					:value="value.length == 6"
					@click="onSelectAllClicked"
				/>
				<template v-for="(day,i) in ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']">
					<v-checkbox v-if="isSelectable(day)"
						:key="`box-${i}`"
						v-model="value"
						:label="day | capitalize"
						:value="day"
					/>
					<v-checkbox v-else
						:key="`box-${i}`"
						input-value="true"
						value
						disabled
						:label="day | capitalize"
					/>
				</template>
			</v-card-text>

			<v-card-actions>
				<v-spacer/>
				<v-btn color="primary" text @click="close()">Cancel</v-btn>
				<v-btn color="primary" text @click="confirm()">{{confirmText}}</v-btn>
			</v-card-actions>
		</v-card>

	</v-dialog>
</template>

<script>
import { ref, watch } from "@vue/composition-api";
import { useModelWrapper } from "@/logic/model-wrapper";


export default {
	name: "DaySelectionDialog",
	props: { title:String, confirmText:String, open:Boolean, disabledDays: { type:Array, required:false, default:() => [] } },

	setup(props, {emit}) {
		const value = ref([]);
		const dialog = useModelWrapper(props, emit, "open");

		const close = () => dialog.value = false;
		const confirm = () => {
			emit("input", value.value);
			emit("confirm");
			close();
		};
		const isSelectable = (day) => !props.disabledDays.includes(day);

		watch(dialog, (isOpen) => {
			if(isOpen) value.value = [];
		});

		const onSelectAllClicked = () => {
			const length = value.value.length;
			if(length == 6) value.value = [];
			else value.value = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
				.filter(day => !props.disabledDays.includes(day))
		};

		return { dialog, value, close, confirm, isSelectable, onSelectAllClicked };
	},
};
</script>

<style scoped lang="scss">
	.copy-dialog {
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
	}
</style>
