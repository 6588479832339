<template>
	<v-form>

		<v-menu offset-y
			ref="menuRef"
			v-model="timeMenuOpen"
			:close-on-content-click="false"
			:return-value.sync="start"
			transition="fade-transition"
		>
			<template v-slot:activator="{on, attrs}">
				<v-text-field readonly clearable
					:value="displayTime"
					label="Select Time"
					prepend-icon="mdi-clock-outline"
					v-bind="attrs"
					v-on="on"
				></v-text-field>
			</template>

			<ScheduleTimePicker
				v-model="start"
				:invalidTimes="occupiedTimes"
				@save="menuRef.save($event)"
				@cancel="timeMenuOpen = false"
				@error="$emit('error', $event)"
			/>
		</v-menu>

		<v-card flat>
			<v-card-text>
				<v-row class="mb-4" justify="space-between">
					<v-col class="text-left">
						<span class="display-3 font-weight-light" v-text="setpoint"></span>
						<v-icon dense class="text--secondary mb-2 ml-1">mdi-temperature-fahrenheit</v-icon>
					</v-col>
				</v-row>

				<TemperatureSlider
					v-model.number="setpoint"
					:min="minSetpoint" :max="maxSetpoint"
				/>
			</v-card-text>
		</v-card>

	</v-form>
</template>

<script>
import { ref, watch, computed } from "@vue/composition-api";
import TemperatureSlider from "@/components/TemperatureSlider";
import ScheduleTimePicker from "./ScheduleTimePicker";
import settings from "@/config/settings.json";
import fromMilitaryTime from "@/filters/from-military-time";

const { min:minSetpoint, max:maxSetpoint } = settings.temperature;

const props = {
	schedule: { required:true, type:Array },
	event: { type:Object, default: () => ({start:null, setpoint:72}) },
};

export default {
	name: "ScheduleEventForm",
	components: { ScheduleTimePicker, TemperatureSlider },
	props,
	emits: ["changed", "save", "error"],

	setup(props, {emit}) {
		const start = ref(props.event.start);
		const setpoint = ref(props.event.setpoint);

		const occupiedTimes = computed(() => {
			const eventTime = props.event.start;
			return props.schedule
				.map(event => event.start)
				.filter(time => time !== eventTime );
		});

		watch([start, setpoint], ([start, setpoint]) => {
			emit("changed", {start, setpoint});
		});

		const modes = [ "Off", "Heat", "Cool" ];

		const menuRef = ref(null);
		const timeMenuOpen = ref(false);


		const resetData = () => {
			start.value = null;
			setpoint.value = 72;
		};

		const submit = () => {
			emit("save", {start:start.value, setpoint:setpoint.value});
			resetData();
		};
		const cancel = resetData.bind(null);

		const invalid = computed(() => {
			return start.value === null;
		});

		const displayTime = computed(() => fromMilitaryTime(start.value));
		return {setpoint, start, modes, menuRef, timeMenuOpen, submit, cancel, invalid, occupiedTimes, minSetpoint, maxSetpoint, displayTime};
	},

}
</script>
