<template>
	<div class="schedules-page">
		<router-view v-if="loaded"></router-view>
		<template v-else>
			<v-skeleton-loader class="my-" height="52" type="image" boilerplate>
			</v-skeleton-loader>

			<v-skeleton-loader class="mt-6 mb-n2 ml-3" max-width="52" type="text" boilerplate>
			</v-skeleton-loader>

			<v-skeleton-loader class="my-3" type="list-item-avatar-three-line@4">
			</v-skeleton-loader>
		</template>
	</div>
</template>

<script>
import { useSchedule } from "@/composables/schedule";

export default {
	setup() {
		const { loaded } = useSchedule();
		return { loaded };
	}
}

</script>

<style scoped>
	.schedules-page {
		height: 100%;
		width: 100%;
	}
</style>
