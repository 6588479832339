<template>
	<v-time-picker
		ref="picker"
		v-model="time"
		:allowed-minutes="allowedMinutes"
		@click:hour="onHourClicked"
		@update:period="onPeriodUpdated"
		full-width
		ampm-in-title
	>
		<v-spacer/>
		<v-btn text color="primary" @click="$emit('cancel'); selectHour()">Cancel</v-btn>
		<v-btn text color="primary" @click="$emit('save', time); selectHour()" :disabled="time === null">Select</v-btn>
	</v-time-picker>
</template>

<script>
import { reactive, toRefs, computed } from "@vue/composition-api";
import useTimePicker from "@/composables/useTimePicker";


const props = {
	invalidTimes: { type:Array, default:() => [] },
	value: { required:true, default:null },
};

export default {
	name: "ScheduleTimePicker",
	props,

	setup(props, {emit}) {
		const state = reactive({
			picker: null,
			time: computed({
				get: () => props.value,
				set: (value) => {
					if(isValidTime(value)) emit("input", value);
					else handleInvalidTimeSelected(value);
				}
			}),
		});

		const selectHour = () => state.picker.selectingHour = true;
		const isValidTime = (time) => !props.invalidTimes.includes(time);
		const handleInvalidTimeSelected = (time) => {
			emit("input", null);
			emit("error", `Invalid time. ${time} already used.`);
			selectHour();
		};

		const { allowedMinutes, onPeriodUpdated, onHourClicked } = useTimePicker(() => props.invalidTimes);
		return { ...toRefs(state), selectHour, allowedMinutes, onPeriodUpdated, onHourClicked };
	},
}
</script>
