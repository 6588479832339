<template>
	<v-timeline dense>

		<v-timeline-item small
			class="white--text pb-2 mr-4 previous-day-event"
			:color="dotColor"
			v-if="!hidePrevious && previousDayEvent"
		>
			<template v-slot:icon>
				<v-icon color="grey darken-2">mdi-circle-outline</v-icon>
			</template>
			<DayEventCard no-time-icon
				class="day-event-card"
				color="grey lighten-1"
				description="From previous day"
				:class="{'shake' : previousDayCard.shaking}"
				:event="previousDayEvent"
				@click.native="shakeCard(previousDayCard)"
			/>
		</v-timeline-item>


		<template v-for="event in day.events">
			<v-timeline-item small
				class="white--text pb-2 mr-4 current-day-event"
				:color="dotColor"
				:key="`${day.name}-${event.start}`"
			>
				<template v-slot:icon>
					<v-icon color="grey darken-2">mdi-circle-outline</v-icon>
				</template>

				<DayEventCard
					class="day-event-card"
					color="deep-purple accent-2"
					icon="mdi-clock-outline"
					:event="event"
					@click.native="$emit('event:clicked', event)"
				>
					<span class="font-weight-bold">{{event.start | fromMilitaryTime}}</span>
				</DayEventCard>
			</v-timeline-item>
		</template>


		<v-timeline-item small
			class="white--text mt-2 mr-4 next-day-event"
			:color="dotColor"
		>
			<template v-slot:icon>
				<v-icon color="grey darken-2">mdi-circle-outline</v-icon>
			</template>
			<h4 class="mt-3 pb-2 text--secondary">To next day</h4>
		</v-timeline-item>

	</v-timeline>
</template>

<script>
import { reactive, computed } from "@vue/composition-api"
import Day from "@/models/day";
import DayEventCard from "./DayEventCard"
import useSnackbar, { CLOSE_ACTION } from "@/composables/useSnackbar";

export default {
	name: "DayEventList",
	props: {
		day:{ type:Day, required:true },
		hidePrevious: { type:Boolean, default:() => false },
	},
	emits: ["event:clicked"],
	components: { DayEventCard },
	setup(props) {

		const previousDayEvent = computed(() => {
			let day = props.day;
			for(let i=0; i<7; ++i) {
				day = day.prev;
				if(!day) return null;

				const events = day.events;
				if(events.length) return events[events.length -1 ];
			}
			return null;
		});

		const dotColor = "grey darken-3";
		const snackbar = useSnackbar();

		const previousDayCard = reactive({ shaking:false, timerId:null, shakeCounter:0 });
		const shakeCard = (card) => {
			if(card.shaking) return;
			if(card.timerId) clearTimeout(card.timerId);

			card.shaking = true;
			card.timerId = setTimeout(() => card.shaking = false, 500);
			card.shakeCounter++;

			const everyThirdClick = card.shakeCounter % 3 === 0;
			if(everyThirdClick) snackbar.show("Select previous day to edit", {action:CLOSE_ACTION});
		};

		return { previousDayEvent, dotColor, shakeCard, previousDayCard };
	}
}
</script>

<style lang="scss" scoped>
	.full-width {
		width: 100%;
	}

	.setpoint-icon {
		margin-right: 3px;
		margin-top: -3px;
	}

	.day-event-card {
		margin-top: 17px;
	}
</style>
